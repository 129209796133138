<template>
  <LazyHydrate when-visible>
    <footer class="g-footer-list">
      <div class="bb-container">
        <div class="g-footer-list__content">
          <div class="g-footer-list__copy">
            © helloBB 2023 — {{ $t("footer.reservedRights") }}
            <a href="http://test.skimlinks.com">sk</a>
          </div>
          <div class="g-footer-list__social">
            <FooterSocialIcons></FooterSocialIcons>
          </div>
          <div class="g-footer-list__app">
            <CtaAppLinks></CtaAppLinks>
          </div>
        </div>
      </div>
    </footer>
  </LazyHydrate>
</template>

<script>
import CtaAppLinks from "../modules/cta/CtaAppLinks.vue";
import FooterSocialIcons from "./components/FooterSocialIcons.vue";
import LazyHydrate from "vue-lazy-hydration";
export default {
  name: "FooterList",
  components: {
    FooterSocialIcons,
    CtaAppLinks,
    LazyHydrate
  },
};
</script>
<style></style>
