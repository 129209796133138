import 'babel-polyfill';
import { sync } from 'vuex-router-sync'
import Vue from 'vue';
import Vuex from 'vuex';
import Meta from 'vue-meta'
import App from './layout/App.vue';
import './global-components';
import ParserApi from './plugins/parser'
import FormatUrlHelloBB from './plugins/string'
import TrackPinterest from './plugins/pìnterest'
import Utils from './plugins/utils'
import Alerts from './plugins/alerts'
import { createRouter } from './router';
import createStore from './store';
import VueClipboard from 'vue-clipboard2';
import VueMq from 'vue-mq'
import PortalVue from 'portal-vue'
import VueAppInsights from 'vue-application-insights'
import Mixpanel from './plugins/mixpanel'
import FloatingVue from "floating-vue";
import VueI18n from 'vue-i18n';

import i18nMessages from "./constants/i18n/messages";

Vue.use(PortalVue)
Vue.use(VueClipboard);
Vue.use(Vuex);
Vue.use(Meta, {
    ssrAppId: 1 // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
});

Vue.use(ParserApi);
Vue.use(Mixpanel);

Vue.use(VueMq, {
    breakpoints: {
        // mobile: 480,
        // tablet: 640,
        // tabletLand: 767,
        // laptop: 991,
        // desktop: Infinity,


        m480: 480,
        m576: 576,
        m768: 768,
        m992: 992,
        m1200: Infinity,

    },

    defaultBreakpoint: 'm480' // Valor per defecte a SSR, cal tenir en compte que en Server Side no sabem tipus dispositiu
    // https://nitayneeman.com/posts/combining-server-side-rendering-and-responsive-design-using-react/

})

Vue.use(FormatUrlHelloBB);
Vue.use(TrackPinterest);

Vue.use(Utils);

Vue.config.errorHandler = function (err, vm, info) {
    if (vm) {
        vm?.$logErrorsAndExceptions('app.js.error', err);
        if (info) vm?.$logErrorsAndExceptions('app.js.error', info);
        return
    }
    console.log(err);
}

Vue.use(FloatingVue, {
    themes: {
        "warning-tooltip": {
            $extend: "tooltip",
            triggers: ["hover", "click", "focus", "touch"],
        },
    },
});

Vue.use(VueI18n);

export const createApp = async (context) => {
    const store = createStore(context);

    let session
    // A server side context té la sessió, se l'hem establert nosaltres abans. En canvi en client és __INITIAL_STATE i
    // té valor null la session (el vue-ssr rendering no té en compte la sessió sinó només l'estat vuex).
    // Però com que a server side també guardem la sessió al estat vuex aleshores la podem aconseguir d'allà
    if (context.session) {
        // SERVER SIDE
        session = context.session
    } else if (context.state?.session) {
        session = context.state?.session
    }

    if (session) {
        store.state.user = session.user;
        if (session.cart) store.state.cart = session.cart;
        store.state.isAuthenticated = !!session.user;
        store.state.auth_token = session.user ? session.user.token : null;
    }

    if (!store.state.isAuthenticated) {
        if (context.cookies) {
            if (context.cookies.sourceApp && context.cookies.auth_token) {
                store.state.isAuthenticated = true;
                store.state.auth_token = context.cookies.auth_token;

                // This `await` is necessary because otherwise Vue prints this
                // error:
                //
                //     The client-side rendered virtual DOM tree is not matching server-rendered content.
                await store.dispatch("getUserApp").then((u) => {
                    store.commit("setUserOnServerSide", u);
                }).catch((error) => {
                    console.error('Error fetching app user:', error);
                });
            }
        }
    }

    if (context?.state?.guestCountry) {
        store.state.guestCountry = context.state.guestCountry;
    }

    const i18n = new VueI18n({
        locale: store?.getters?.preferredLanguage || 'es',
        messages: i18nMessages,
        fallbackLocale: {
            "es-MX": ["es"],
            "fr-FR": ["es"],
            default: ["es"],
        },
        silentFallbackWarn: process.env.NODE_ENV === 'production',
    });

    const router = createRouter(store, i18n);

    // https://ajayrc.medium.com/application-insights-using-azure-and-vuejs-error-handling-and-event-logging-in-front-end-to-40874d76f152
    if ((typeof location !== 'undefined') && process.env.NODE_ENV === 'production') {
        Vue.use(VueAppInsights, {
            id: process.env.APP_INSIGHTS_KEY,
            router
        })
    }

    if (typeof window !== 'undefined') {
        window.__INITIAL_IS_AUTHENTICATED__ = store.state.isAuthenticated;
    }

    Vue.use(Alerts, store);

    // sync so that route state is available as part of the store
    sync(store, router);

    const app = new Vue({
        store,
        router,
        i18n,
        data() {
            return {
                userId: null
            }
        },
        mounted() {
            //const userId = store.state.user?.id;
            let userId = store.state.user?.loggedUserId;
            if (userId == null) {
                userId = store.state.user?.id;
            }
            if (userId != null) {
                // eslint-disable-next-line no-undef
                mixpanel.identify(userId);
                this.userId = userId;
            }
            if (userId && (process.env.NODE_ENV === 'production')) {
                // eslint-disable-next-line no-undef
                gtag('config', 'G-GGMC1VLYC3', {
                    'user_id': userId
                });
            }
            if (this.userId != null) mixpanel.track("Visita", { "Page Name": this.$route.path, "Platform": this.$getPlatform() });
            router.afterEach((to) => {
                console.log('router.afterEach', to);
                console.log('userId', this.userId)
                if (this.userId != null) {
                    // eslint-disable-next-line no-undef
                    mixpanel.identify(userId);
                    mixpanel.track("Visita", { "Page Name": to?.path, "Platform": this.$getPlatform() });
                }
            });
            //add watch for store.state.user?.loggedUserId
            this.$watch(() => store.state.user?.loggedUserId, (newValue, oldValue) => {
                console.log('store.state.user?.loggedUserId', newValue, oldValue)
                if (newValue != null) {
                    // eslint-disable-next-line no-undef
                    mixpanel.identify(newValue);
                    this.userId = newValue;
                    mixpanel.track("Visita", { "Page Name": this.$route.path, "Platform": this.$getPlatform() });
                }
            });
        },
        methods: {
            trackConversion(conversionData) {
                try {
                    if (process.env.NODE_ENV === 'production') {
                        const conversionPayload = {
                            'email': conversionData.email,
                        };
                        gtag('set', 'user_data', conversionPayload);
                    }
                }
                catch (error) {
                    console.error('Error tracking conversion:', error);
                }
            }
        },
        render: h => h(App),
    });

    return { app, router, store, session };
};
