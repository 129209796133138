<template>
  <div>
    <nav class="m-header" v-if="countryLoaded || isAuthenticated">
      <router-link to="/catalogo" v-if="featureCatalogAvailable">
        <div class="m-header__item">
        <span class="button button--light">
          {{ $t("header.catalog") }}
        </span>
        </div>
      </router-link>


      <!-- <a href="/blog/categorias/listas-de-ejemplo">
        <span style="display: block;" class="m-header__item">
          <span
              class="button button--light"
          >Listas de ejemplo</span
          >
        </span>
      </a> -->

      <a href="/programa-de-recompensas" v-if="featureEcommerceAvailable">
        <span style="display: block;" class="m-header__item">
          <span
              class="button button--light"
          >{{ $t("header.rewardsProgram") }}</span
          >
        </span>
      </a>

      <a href="/hucha-de-nacimiento" v-if="!isAuthenticated && !hidePiggyBank">
        <span class="m-header__item">
          <span
              class="button button--light"
          >{{ $t("header.contributions") }}</span
          >
        </span>
      </a>
      <router-link v-else-if="!hidePiggyBank && (stripeAccountId == null || stripeAccountId == '') && contributionsFlag" to="/mi-hucha/crear">
        <div class="m-header__item">
        <span class="button button--light">
          {{ $t("header.contributions") }}
        </span>
        </div>
      </router-link>
      <a href="/blog" v-if="featureBlogAvailable">
        <span style="display: block;" class="m-header__item">
          <!-- <router-link to="/blog" class="button button--light">Blog</router-link> --> <!-- Force SSR -->
          <span class="button button--light"
          >{{ $t("header.blog") }}</span
          >
        </span>
      </a>
    </nav>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import Features from "../../Mixins/Features";

export default {
  name: "HeaderNav",
  mixins: [
    Features,
  ],
  data() {
    return {
      countryLoaded: false
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      isAuthenticated: "isAuthenticated",
      contributionsFlag: "contributionsFlag",
      hidePiggyBank: "hidePiggyBank",
      isInternational: "isInternational",
    }),
    stripeAccountId() {
      return this.user?.stripeAccountId || "";
    },
  },
};
</script>
