import Vue from 'vue';
import Router, { NavigationFailureType } from 'vue-router';
import Meta from 'vue-meta'

Vue.use(Router);
Vue.use(Meta, {
    ssrAppId: 1 // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
});

import { countryCode, featureContributionsAvailable } from "../store/getters";

/**
 * Helper function intended to be used with `Array.prototype.filter` to return
 * only unique elements in the array.
 *
 * This only works if the array contains primitive types, i.e. types whose
 * equality can be compared with `===`.
 *
 * @example
 *     // Returns `["foo", "bar"]`.
 *     ["foo", "bar", "foo"].filter(unique)
 */
function unique(item, i, arr) {
    return arr.indexOf(item) === i;
}

/**
 * Get the path pattern for a route, in the configured language.
 *
 * @param {object} i18n Instance of `vue-i18n` library.
 * @param {string} routeName The name of the route for which we want to get the
 *     translated route pattern.
 * @returns {string} The path pattern in the configured language.
 */
function getPreferredRoutePath(i18n, routeName) {
    const key = `routes.${routeName}`;

    const routePath = i18n.t(key);
    if (routePath === key) {
        return "";
    }

    return routePath;
}

/**
 * Get all possible aliases, in all configured languages, for the given route
 * name.
 *
 * @param {object} i18n Instance of `vue-i18n` library.
 * @param {string} routeName The name of the route for which we want to get translations.
 * @returns {Array} All possible route patterns.
 */
function getRouteAliases(i18n, routeName) {
    return Object
        .entries(i18n.messages)
        .map(([language, literals]) => {
            return (literals?.routes || {})[routeName];
        })
        .filter(route => !!route);
}

/**
 * Returns the routes that will be
 *
 * @param {object} functions Functions that will be used during the creation of
 *     the array.
 * @returns {Array} Routes.
 */
const routes = ({
    translateRoute,
    featureContributionsAvailable,
    locationCountry,
}) => {
    const originalRoutes = [
        { path: '/', component: () => import(/* webpackChunkName: "home" */  '../modules/home/HomePage'), name: 'home',
            beforeEnter: (to, from, next) => {
                if (locationCountry() === 'FR') {
                    next({ name: 'HomeFR' });
                }
                else next();
            }
         },
        { path: '/latam', component: () => import(/* webpackChunkName: "home" */  '../modules/home/HomePageINT'), name: 'HomeINT' },
        { path: '/fr', component: () => import(/* webpackChunkName: "home" */  '../modules/home/HomePageFR'), name: 'HomeFR' },

        { path: '/about', component: () => import(/* webpackChunkName: "about" */ '../modules/about/index.vue'), name: 'about' },
        { path: '/login', component: () => import(/* webpackChunkName: "login" */ '../modules/login/LoginPage'), name: 'login' },
        { path: '/signup', component: () => import(/* webpackChunkName: "signup" */ '../modules/login/SignUpPage'), name: 'signup' },
        { path: '/recoverpwd', component: () => import(/* webpackChunkName: "recoverpwd" */ '../modules/login/RecoverPage'), name: 'recoverpwd' },
        { path: '/restablecer-pwd', component: () => import(/* webpackChunkName: "restablecer-pwd" */ '../modules/login/ResetPassword'), name: 'resetpwd', meta: { header: false, footer: false } },
        { path: '/invitacion', component: () => import(/* webpackChunkName: "invitacion" */ '../modules/login/Invitacion'), name: 'invitacion' },
        { path: '/cookies', component: () => import(/* webpackChunkName: "cookies" */ '../modules/login/CookiesPage'), name: 'cookies' },
        { path: '/eliminacion-cuenta', component: () => import(/* webpackChunkName: "eliminacionCuenta" */ '../modules/pages/eliminarCuenta'), name: 'eliminarcuenta' },
        { path: '/politica-privacidad', component: () => import(/* webpackChunkName: "privacidad" */ '../modules/login/PrivacityPage'), name: 'privacity' },
        { path: '/legal', component: () => import(/* webpackChunkName: "legal" */ '../modules/login/LegalPage'), name: 'legal' },
        { path: '/afiliacion-y-publicidad', component: () => import(/* webpackChunkName: "afiliacion" */ '../modules/login/AdvertisingAndAffiliationPage'), name: 'advertisingandaffiliation' },
        { path: '/mapa-web', component: () => import(/* webpackChunkName: "afiliacion" */ '../modules/pages/mapaWeb'), name: 'mapaweb' },

        { path: '/devoluciones', redirect: { name: 'faq', hash: '#nav-devoluciones' } },
        { path: '/envios', redirect: { name: 'faq', hash: '#nav-envios' } },
        { path: '/programa-de-recompensas', redirect: { name: 'faq', hash: '#nav-programa-recompensas' } },
        { path: '/preguntas-frecuentes', component: () => import(/* webpackChunkName: "faq" */ '../modules/pages/ecommerceFaq.vue'), name: 'faq' },
        { path: '/sobre-nosotros', component: () => import(/* webpackChunkName: "nosotros" */ '../modules/pages/pagesNosotros.vue'), name: 'nosotros' },
        { path: '/sobre-hellobb', redirect: { name: 'faq', hash: '#nav-hellobb' } },
        { path: '/ayuda-configuracion', redirect: { name: 'faq', hash: '#nav-configuracion' } },
        { path: '/ayuda-fondos', redirect: { name: 'faq', hash: '#nav-fondos' } },
        { path: '/compartiendo-y-gestionado-mi-lista', redirect: { name: 'faq', hash: '#nav-regalo' } },
        { path: '/haciendo-mi-lista', redirect: { name: 'faq', hash: '#nav-lista' } },
        { path: '/hucha-de-aportaciones', redirect: { name: 'faq', hash: '#nav-hucha' } },

        { path: '/configuracion', component: () => import(/* webpackChunkName: "configuration" */ '../modules/configuration/ConfigurationPage'), name: 'configuration', meta: { private: true } },
        { path: '/configuracion/mi-lista', component: () => import(/* webpackChunkName: "configuration-mylist" */ '../modules/configuration/ConfigurationMyListPage'), name: 'configuration-mylist', meta: { private: true } },
        {
            path: '/configuracion/mi-hucha',
            component: () => featureContributionsAvailable()
                ? import(/* webpackChunkName: "configuration-mypiggybank" */ '../modules/configuration/ConfigurationMyPiggyBankPage')
                : import(/* webpackChunkName: "not-available-international" */ '../modules/NotAvailableInternationalPage.vue'),
            name: 'configuration-mypiggybank',
            meta: { private: true },
        },

        { path: '/mi-lista', component: () => import(/* webpackChunkName: "owner-list" */ '../modules/ownerlist/OwnerListPage.vue'), name: 'owner-list', meta: { private: true, footer: 'FooterList' } },

        // NOTE: Some components depend on the names of the constructor route
        // names to show or hide HTML elements. If constructor routes are
        // modified, added, or removed, then those components might need to be
        // updated.
        //
        // For more information, run `git blame` on the lines of this comment
        // and take a look at the other files modified in the same commit.
        { path: '/constructor', component: () => import(/* webpackChunkName: "list-constructor" */ '../modules/constructor/ConstructorSelector.vue'), name: 'list-constructor', meta: { private: true, footer: 'FooterList' } },
        { path: '/nuevo-producto', component: () => import(/* webpackChunkName: "add-product" */ '../modules/constructor/AddProductPage.vue'), name: 'add-product', meta: { private: true, footer: 'FooterList' } },
        { path: '/constructor/:brand/:id', component: () => import(/* webpackChunkName: "constructor-product" */ '../modules/constructor/ConstructorProductPage.vue'), name: 'constructor-product', meta: { private: true, header: false, footer: 'FooterList' } },
        { path: '/constructor/blog/posts/:slug', component: () => import(/* webpackChunkName: "ConstructorBlogArticle" */ '../modules/constructor/BlogArticle.vue'), name: 'ConstructorBlogArticle', meta: { private: true, header: false, footer: 'FooterList' } },

        // id -> En el cas dels productes de catàleg serà el listProductId, per poder treballar amb el tema de variants/opcions (el id de producte en variants es compartit). Cas de productes free serà el id de producte
        { path: '/mi-lista/comprar/:productType/:id', component: () => import(/* webpackChunkName: "owner-list-shop-product" */ '../modules/ownerlist/ListProductPageComprar.vue'), name: 'owner-list-shop-product', meta: { private: true, footer: 'FooterList' } },
        // id -> En el cas dels productes de catàleg serà el listProductId, per poder treballar amb el tema de variants/opcions (el id de producte en variants es compartit). Cas de productes free serà el id de producte
        { path: '/mi-lista/product/:productType/:id', component: () => import(/* webpackChunkName: "owner-list-edit-product" */ '../modules/ownerlist/EditProductPage.vue'), name: 'owner-list-edit-product', meta: { private: true, footer: 'FooterList' } },
        // Routes per a Mi-Hucha
        {
            path: '/mi-hucha/crear',
            component: () => import(/* webpackChunkName: "mi-hucha-crear" */ '../modules/hucha/HuchaCrear.vue'),
            name: 'hucha-crear',
            meta: { private: true, footer: 'FooterList' },
            beforeEnter: (to, from, next) => {
                if (featureContributionsAvailable()) {
                    next();
                } else {
                    next({ name: 'not-available-international' });
                }
            }
        },
        { path: '/not-available-international', component: () => import(/* webpackChunkName: "not-available-international" */ '../modules/NotAvailableInternationalPage.vue'), name: 'not-available-international', meta: { private: false } },
        {
            path: '/hucha-de-nacimiento',
            component: () => import(/* webpackChunkName: "mi-hucha-crear" */ '../modules/hucha/HuchaCrear.vue'),
            name: 'hucha-de-nacimiento',
            meta: { private: false },
            beforeEnter: (to, from, next) => {
                console.log('featureContributionsAvailable = ' + featureContributionsAvailable());
                if (featureContributionsAvailable()) {
                    next();
                } else {
                    next({ name: 'not-available-international' });
                }
            }
        },
        {
            path: '/mi-hucha/',
            component: () => import(/* webpackChunkName: "mi-hucha" */ '../modules/hucha/Hucha.vue'),
            name: 'hucha',
            meta: { private: true, footer: 'FooterList' },
            beforeEnter: (to, from, next) => {
                //console.log('store.getters.featureContributionsAvailable = ' + store.getters.featureContributionsAvailable);
                if (featureContributionsAvailable()) {
                    next();
                } else {
                    next({ name: 'not-available-international' });
                }
            }
        },
        // productId -> En el cas dels productes de catàleg serà el listProductId, per poder treballar amb el tema de variants/opcions (el id de producte en variants es compartit). Cas de productes free serà el id de producte
        { path: '/guest/:id/product/:productId/:productType', component: () => import(/* webpackChunkName: "guestProduct" */ '../modules/regalador/ListProduct.vue'), name: 'guestProduct', meta: { header: 'HeaderList', footer: 'FooterList' } },
        //{ path: '/guest/:id/onboarded', component: () => import(/* webpackChunkName: "guest" */ '../modules/regalador/List.vue'), name: 'guest', meta: { header: 'HeaderList', footer: 'FooterList' } },
        { path: '/en-camino/:id/:productId/:productType', component: () => import(/* webpackChunkName: "enCamino" */ '../modules/regalador/ListEnCamino.vue'), name: 'enCamino', meta: { header: 'HeaderList', footer: 'FooterList' } },
        { path: '/catalogo', component: () => import(/* webpackChunkName: "catalogue" */ '../modules/catalogue/CataloguePage.vue'), name: 'catalogue', meta: { header: 'HeaderCatalogo' } },

        { path: '/categoria/:slug', component: () => import(/* webpackChunkName: "category" */ '../modules/category/CategoryPage.vue'), name: 'category', meta: { header: 'HeaderCatalogo' } },

        { path: '/resultados', component: () => import(/* webpackChunkName: "search" */ '../modules/product/SearchPage.vue'), name: 'search', meta: { header: 'HeaderCatalogo' } },
        { path: '/basesconcurso', component: () => import(/* webpackChunkName: "basesconcurso" */ '../modules/concurso/bases.vue'), name: 'basesconcurso' },

        { path: '/onboarding-1', component: () => import(/* webpackChunkName: "signupOnboarding" */ '../modules/onboarding/OnboardingStep1.vue'), name: 'onboarding1', meta: { private: true, footer: false, header: false } },
        { path: '/onboarding-2', component: () => import(/* webpackChunkName: "signupOnboarding" */ '../modules/onboarding/OnboardingStep2.vue'), name: 'onboarding2', meta: { private: true, footer: false, header: false } },
        { path: '/onboarding-3', component: () => import(/* webpackChunkName: "signupOnboarding" */ '../modules/onboarding/OnboardingStepSurvey.vue'), name: 'onboardingSurvey', meta: { private: true, footer: false, header: false } },
        { path: '/onboarding-colaborador', component: () => import(/* webpackChunkName: "signupOnboarding" */ '../modules/onboarding/OnboardingStepColaborador.vue'), name: 'onboardingColaborador', meta: { private: true, footer: false, header: false } },
        { path: '/onboarding-invitacion-enviada', component: () => import(/* webpackChunkName: "signupOnboarding" */ '../modules/onboarding/OnboardingStepInvitacionEnviada.vue'), name: 'OnboardingStepInvitacionEnviada', meta: { private: true, footer: false, header: false } },
        { path: '/onboarding-puedes-empezar', component: () => import(/* webpackChunkName: "signupOnboarding" */ '../modules/onboarding/OnboardingStepPuedesEmpezar.vue'), name: 'OnboardingStepPuedesEmpezar', meta: { private: true, footer: false, header: false } },
        { path: '/onboarding-como-nos-has-encontrado', component: () => import(/* webpackChunkName: "signupOnboarding" */ '../modules/onboarding/OnboardingStepHowDidYouFindUs.vue'), name: 'OnboardingStepHowDidYouFindUs', meta: { private: true, footer: false, header: false } },
        { path: '/onboarding-motivo-registro', component: () => import(/* webpackChunkName: "signupOnboarding" */ '../modules/onboarding/OnboardingStepWhyDidYouRegister.vue'), name: 'OnboardingStepWhyDidYouRegister', meta: { private: true, footer: false, header: false } },

        { path: '/guest/:id', component: () => import(/* webpackChunkName: "guest" */ '../modules/regalador/List.vue'), name: 'guest', meta: { header: 'HeaderList', footer: 'FooterList' } },
        { path: '/guest/:id/1', component: () => import(/* webpackChunkName: "OnboardingGuestStep1" */ '../modules/onboarding-guest/OnboardingGuestStep1.vue'), name: 'OnboardingGuestStep1', meta: { footer: false, header: 'HeaderOnboardingGuest' } },
        { path: '/guest/:id/2', component: () => import(/* webpackChunkName: "OnboardingGuestStep2" */ '../modules/onboarding-guest/OnboardingGuestStep2.vue'), name: 'OnboardingGuestStep2', meta: { footer: false, header: 'HeaderOnboardingGuest' } },
        { path: '/guest/:id/3', component: () => import(/* webpackChunkName: "OnboardingGuestStep3" */ '../modules/onboarding-guest/OnboardingGuestStep3.vue'), name: 'OnboardingGuestStep3', meta: { footer: false, header: 'HeaderOnboardingGuest' } },
        { path: '/guest-ads/:id', component: () => import(/* webpackChunkName: "ListAds" */ '../modules/regalador/ListAds.vue'), name: 'ListAds', meta: { header: 'HeaderList', footer: 'FooterList' } },
        { path: '/guest-ads-2/:id', component: () => import(/* webpackChunkName: "ListAds2" */ '../modules/regalador/ListAds2.vue'), name: 'ListAds2', meta: { header: 'HeaderList', footer: 'FooterList' } },

        { path: '/blog', component: () => import(/* webpackChunkName: "Blog" */ '../modules/blog/Blog.vue'), name: 'Blog', meta: { header: 'HeaderBlog' } },
        { path: '/blog/posts/:slug', component: () => import(/* webpackChunkName: "BlogArticle" */ '../modules/blog/BlogArticle.vue'), name: 'BlogArticle', meta: { header: 'HeaderBlog' } },
        { path: '/blog/categorias/:slug', component: () => import(/* webpackChunkName: "BlogCategory" */ '../modules/blog/BlogCategory.vue'), name: 'BlogCategory', meta: { header: 'HeaderBlog' } },

        { path: '/blog/autor/antonella', component: () => import(/* webpackChunkName: "AntonellaAutor" */ '../modules/blog/AntonellaAutor.vue'), name: 'AntonellaAutor', meta: { header: 'HeaderBlog' } },


        { path: '/seleccion/:slug/:id', component: () => import(/* webpackChunkName: "collection" */ '../modules/collections/CollectionPage.vue'), name: 'collection', meta: { header: 'HeaderCatalogo' } },

        // Només URL acabades en numero https://router.vuejs.org/guide/essentials/route-matching-syntax.html#custom-regex-in-params
        { path: '/:brand/:id(\\d+)', component: () => import(/* webpackChunkName: "product" */ '../modules/product/ProductPage.vue'), name: 'product' },
        { path: '/catalogo/:name/:id(\\d+)', component: () => import(/* webpackChunkName: "product" */ '../modules/product/ProductPage.vue'), name: 'product-new' },

        // landings
        { path: '/landing/babyshower', component: () => import(/* webpackChunkName: "landing-1" */ '../modules/landings/LandingBabyShower.vue'), name: 'landing-1', meta: { header: false, footer: false } },
        { path: '/landing/listabebe', component: () => import(/* webpackChunkName: "landing-2" */ '../modules/landings/LandingNoList.vue'), name: 'landing-2', meta: { header: false, footer: false } },
        { path: '/landing/sorteo-hellobb-box', component: () => import(/* webpackChunkName: "landing-3" */ '../modules/landings/LandingSorteo2.vue'), name: 'landing-3', meta: { header: false, footer: false } },
        { path: '/landing/afiliado', component: () => import(/* webpackChunkName: "landing-4" */ '../modules/landings/LandingAfiliado.vue'), name: 'landing-4', meta: { header: false, footer: false } },
        { path: '/landing/listabebe-video', component: () => import(/* webpackChunkName: "landing-5" */ '../modules/landings/LandingNoListVideo.vue'), name: 'landing-5', meta: { header: false, footer: false } },

        // landings-internacional
        { path: '/landing/mesa-regalos', component: () => import(/* webpackChunkName: "landing-6" */ '../modules/landings/LandingINTMesaRegalos.vue'), name: 'landing-6', meta: { header: false, footer: false, video: false } },
        { path: '/landing/mesa-regalos-video', component: () => import(/* webpackChunkName: "landing-6" */ '../modules/landings/LandingINTMesaRegalos.vue'), name: 'landing-6-video', meta: { header: false, footer: false } },
        { path: '/landing/mesa-babyshower', component: () => import(/* webpackChunkName: "landing-int-babyshower" */ '../modules/landings/LandingINTBabyShower.vue'), name: 'landing-int-babyshower', meta: { header: false, footer: false, video: false } },



        { path: '/error', component: () => import(/* webpackChunkName: "error" */ '../modules/Error'), name: 'error' },
        { path: '/pagina-no-encontrada', component: () => import(/* webpackChunkName: "notfound" */ '../modules/NotFoundPage'), name: 'notfound' },
        { path: '/hasta-pronto', component: () => import(/* webpackChunkName: "seeyousoon" */ '../modules/SeeYouSoon'), name: 'seeyousoon' },
        { path: '*', component: () => import(/* webpackChunkName: "catchall" */ '../modules/NotFoundPage') },
        { path: '/link', component: () => import(/* webpackChunkName: "redirect" */ '../components/Redirect'), name: 'link' },
    ];

    return originalRoutes
        .filter(item => !!item)
        .map(route => translateRoute(route));
};

// Evitar warnings continuos a la console del servidor: "NavigationDuplicated: Avoided redundant navigation to current location"
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch((err) => {
        console.log('location:');
        if (typeof location === 'string') console.log(location);
        if (typeof location === 'object') console.log(location);
        if (NavigationFailureType) {
            if (Router.isNavigationFailure(err, NavigationFailureType.duplicated)) {
                console.log('VUE-ROUTER NavigationFailure - Duplicated. Aquest "error"/warning sol ser normal. Es genera al navegar cap a una ruta que ja és la actual/última del historial de navegació de vue-router. Normalment podem ignorar aquest error.');
                return err
            }
            if (Router.isNavigationFailure(err, NavigationFailureType.aborted)) {
                console.log('VUE-ROUTER NavigationFailure - aborted.');
                console.log(err);
                return err
            }
            if (Router.isNavigationFailure(err, NavigationFailureType.cancelled)) {
                console.log('VUE-ROUTER NavigationFailure - cancelled');
                console.log(err);
                return err
            }
            if (Router.isNavigationFailure(err, NavigationFailureType.redirected)) {
                console.log('VUE-ROUTER NavigationFailure -redirected');
                console.log(err);
                return err
            }
        } else {
            if (Router.isNavigationFailure(err)) {
                if (Router.isNavigationFailure(err)) {
                    if (err.name === 'NavigationDuplicated') {
                        console.log('VUE-ROUTER NavigationFailure - Duplicated. Aquest "error"/warning sol ser normal. Es genera al navegar cap a una ruta que ja és la actual/última del historial de navegació de vue-router. Normalment podem ignorar aquest error.');
                        return err
                    }
                    if (err.name === 'NavigationAborted') {
                        console.log('VUE-ROUTER NavigationFailure - aborted.');
                        console.log(err);
                        return err
                    }
                    if (err.name === 'NavigationCancelled') {
                        console.log('VUE-ROUTER NavigationFailure - cancelled');
                        console.log(err);
                        return err
                    }
                    if (err.name === 'NavigationRedirected') {
                        console.log('VUE-ROUTER NavigationFailure -redirected');
                        console.log(err);
                        return err
                    }
                }
            }
        }

        return Promise.reject(err)
    })
}

export function getAllRoutes(store, i18n, translatePath = false) {
    /**
     * Receives a RouteConfig object, and returns a new RouteConfig object that
     * contains additional information that can be used to translate routes.
     *
     * @param {vue-router:RouteConfig} routeConfig `RouteConfig` object from
     *     `vue-router` library.
     * @returns {vue-router:RouteConfig} `RouteConfig` object with additional
     *     information.
     * @see {@link https://v3.router.vuejs.org/api/#routes}
     */
    function translateRoute(routeConfig) {
        const routeName = routeConfig.name;
        if (!routeName) {
            // This shouldn't happen, but if we are passed something that
            // doesn't have a `name` property, return the received value
            // unchanged.
            return routeConfig;
        }

        // Build array with all the aliases that were defined for this route.
        //
        // If the route had a single alias as a string, this will be an array
        // with a single item.
        let existingAliases = routeConfig.alias || [];
        if (typeof existingAliases === "string") {
            existingAliases = [existingAliases];
        }

        // `finalPath` will be the returned value of the `path` property of the
        // route.
        //
        // It will only be change dwhen the `translatePath` flag is set to
        // `true`.
        let finalPath = routeConfig.path;
        if (translatePath) {
            // Try to get a translated path pattern.
            //
            // If no translation is found, fallback to the original (unchanged)
            // value.
            finalPath = getPreferredRoutePath(i18n, routeConfig.name) || routeConfig.path;
        }

        // Build an array that contains:
        //
        //     - The original route aliases; and
        //     - One alias per translated route.
        //
        // See:
        //
        //     https://v3.router.vuejs.org/guide/essentials/redirect-and-alias.html#alias
        const aliases = [
            ...existingAliases,
            ...getRouteAliases(i18n, routeName)
        ]
            // Remove duplicates.
            .filter(unique)
            // If the route's `path` property appears as an alias, remove it.
            .filter((alias) => alias !== finalPath);

        // This is the modified route object.
        const finalRoute = {
            // Copy all the original data.
            ...routeConfig,

            // `finalPath` can be either the original route path, or a
            // translated path.
            path: finalPath,

            meta: {
                // Copy the original value of `meta`.
                ...(routeConfig.meta || {}),

                // For some reason we can't read `to.alias` from the
                // `router.beforeEach` callback,
                aliases,
            },

            // `aliases` contains the original aliases (if any), plus an alias
            // for each possible route translation.
            //
            // See:
            //
            //     https://v3.router.vuejs.org/guide/essentials/redirect-and-alias.html#alias
            alias: aliases,
        };

        return finalRoute;
    }

    return routes({
        translateRoute,

        featureContributionsAvailable: () => featureContributionsAvailable(store.state),
        locationCountry: () => countryCode(store.state),
    });
}

export function createRouter(store, i18n) {
    const router = new Router({
        routes: getAllRoutes(store, i18n),
        mode: 'history',
        scrollBehavior(to, from, savedPosition) {
            let offset = { x: 0, y: 0 }
            const stickyHeaderOffset = { x: 0, y: 90 } // A ull! quina és l'alçada del sticky header     // TODO Sergi -> No se com utilitzar REMS en comptes de pixels?
            if (to.name === 'faq') {
                offset = stickyHeaderOffset
            }

            let position
            if (savedPosition) {
                position = savedPosition // només té valor savedPosition si és navegació a través botons back/next del navegador
                const routesWaitCanScroll = ['catalogue', 'category', 'product', 'constructor', 'list-constructor']
                if (routesWaitCanScroll.includes(to.name)) {
                    return new Promise(resolve => {
                        const unsubscribeFn = this.app.$store.subscribe(mutation => {
                            if (mutation.type === "setCanScroll") {
                                unsubscribeFn();
                                resolve(position);
                            }
                        });
                    });
                }
            }
            else if (to.hash) {
                position = { selector: to.hash, offset }
                //position = { selector: to.hash }
                return new Promise(resolve => {
                    const unsubscribeFn = this.app.$store.subscribe(mutation => {
                        if (mutation.type === "setCanScroll") {
                            unsubscribeFn();
                            resolve(position);
                        }
                    });
                });
            } else if (to.name === from.name) {
                const routesScrollTotop = ['product', 'category']
                if (routesScrollTotop.includes(to.name)) {
                    // Productes relacionats (product) té link que porta a la mateixa pàgina però scroll es queda a sota i el volem adalt
                    // idem per a categories i subcategories
                    position = { x: 0, y: 0 }
                } else {
                    position = false // mantenir scroll si la pàgina és la mateixa
                }
            } else {
                position = { x: 0, y: 0 }
            }
            return position;
        }
    });

    router.beforeEach(async (to, from, next) => {
        // Required to properly detect some changes in state.
        //
        // For example, when a user without products adds their own product.
        if (router.app.$store) {
            router.app.$forceRefreshState();

            // Ensure `i18n.locale` is up-to-date.
            //i18n.locale = router.app.$store.getters.preferredLanguage || 'es';
            i18n.locale = store?.getters?.preferredLanguage || router.app.$store.getters.preferredLanguage || "es";
        }

        if (typeof window !== 'undefined') {
            if (typeof document !== 'undefined') {
                // TODO Hack a eliminar si hi ha encara algún modal antic que no es tanca correctament i bloqueja l'scroll de pàgina
                document.body.style.overflowY = "";
            }
        }

        if (to.meta.private && !store.state.isAuthenticated) {
            next({
                name: 'login',
                params: {
                    wantedRoute: to.fullPath,
                },
            })

            return;
        }

        const hasAliases = to?.meta?.aliases && to.meta.aliases.length > 0;
        if (hasAliases && typeof window !== "undefined") {
            const translatedPath = getPreferredRoutePath(i18n, to.name);

            // Create a new router but only use it as a temporary variable.
            const secondRouter = new Router({
                // Pass `true` to `getAllRoutes` because we want `path` to be
                // the translated route.
                routes: getAllRoutes(store, i18n, true),

                mode: "history",
            });

            // Ask the temporary router to resolve the translated path.
            //
            // This will parse the received navigation object and return
            // results of how the router parsed it.
            const expectedResolvedRoute = secondRouter.resolve({
                ...to,

                path: translatedPath,
            });

            // Use the resolution results from the second router to obtain a
            // path that is both:
            //
            //     - Translated; and
            //     - has all params replaced (i.e. has real values instead of
            //       placeholders like `:id`).
            const expectedPath = expectedResolvedRoute.route.fullPath;

            // If the route we're going to
            if (to.path !== expectedPath) {
                next({
                    path: expectedPath,

                    hash: to.hash,
                    params: to.params,
                    query: to.query,
                });

                return;
            }
        }

        try {
            next()
        } catch (error) {
            console.log(error)
            console.log(error.name)
        }
    });

    return router;
}
