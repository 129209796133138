<template>
  <div v-if="show" class="m-nav__login-wrapper">
    <div v-if="isAuthenticated" class="nav-user">
      <div class="nav-user__mi-lista" v-if="$route.path !== '/mi-lista'">
        <button
          @click.prevent="$router.push({ name: 'owner-list' })"
          v-show="isAuthenticated"
          class="button button--sm button--accent-outline"
        >
          {{$t("header.myList")}}
        </button>
      </div>
      <div
        class="nav-user__tu-cuenta"
        @click="showUserDropdown = !showUserDropdown"
        v-click-outside="config"
      >
        <!-- User account desktop //////////////////////// -->
        <div class="nav-user-account">
          <div class="nav-user-account__main">
            <img
              v-if="hasUserPhoto"
              :src="getImgUrl()"
              width="50"
              height="50"
              sizes="(max-width: 991px) 100vw, 50px"
              alt
              class="nav-user-account__avatar"
            />
            <img
              v-else
              src="../../assets/images/embarazada.jpg"
              width="50"
              height="50"
              srcset="
                ../../assets/images/embarazada-p-500.jpeg   500w,
                ../../assets/images/embarazada-p-800.jpeg   800w,
                ../../assets/images/embarazada-p-1080.jpeg 1080w,
                ../../assets/images/embarazada.jpg         1314w
              "
              sizes="(max-width: 991px) 100vw, 50px"
              alt
              class="nav-user-account__avatar"
            />

            <div class="nav-user-account__name">{{ shorten(user.name, 0) }}</div>

            <div class="nav-user-account__user">
              <div class="nav-user-account__name">{{ shorten(user.name, 30) }}</div>
            </div>

            <div class="nav-user-account__chevron">
              <img src="../../assets/img/ico/ico-chevron-down.svg" alt="" />
            </div>
          </div>

          <div class="nav-user-account__dropdown" v-show="showUserDropdown">
            <div class="nav-config">
              <router-link
                :to="{ name: 'owner-list' }"
                class="nav-config__item nav-config__item--lista"
              >
                <img src="../../assets/img/ico/ico-nav-list.svg" alt="" />
                {{ $t("header.myList") }}
              </router-link>

              <!-- next link only visible in responsive version -->
              <router-link
                v-show="contributionsFlag && contributionsEnabled && !hidePiggyBank"
                :to="{ name: 'hucha' }"
                class="nav-config__item nav-config__item--lista"
              >
                <span class="hucha-count">
                  <img src="../../assets/img/ico/ico-nav-hucha.svg" alt="" />
                  <!-- <div class="hucha-count__count">2</div> -->
                </span>
                {{ $t("header.myPiggybank") }}
              </router-link>

              <router-link
                :to="{ name: 'configuration' }"
                class="nav-config__item nav-config__item--configuracion"
              >
                <img src="../../assets/img/ico/ico-nav-config.svg" alt="" />
                {{ $t("header.settings") }}
              </router-link>

              <button
                @click="logout()"
                class="nav-config__item nav-config__item--cerrar"
                aria-label="Cerrar sesión"
              >
                <img src="../../assets/img/ico/ico-nav-logout.svg" alt="" />
                {{ $t("header.logOut") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!isAuthenticated" class="nav-login">
      <router-link
        to="/login"
        v-show="!isAuthenticated"
        class="button button--accent-outline"
        >{{ $t("header.logIn") }}</router-link
      >
      <router-link to="/signup" v-show="!isAuthenticated" class="button button--accent"
        >{{ $t("header.createYourList") }}</router-link
      >
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CanBeClosedByEscKey from "../../Mixins/CanBeClosedByEscKey";
import CloseOnClickOutside from "../../components/Mixins/CloseOnClickOutside";
import CanLogout from "../../Mixins/CanLogout";

export default {
  name: "HeaderUser",
  mixins: [CanBeClosedByEscKey, CloseOnClickOutside, CanLogout],
  data() {
    return {
      show: false,
      showUserDropdown: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      user: "user",
      contributionsFlag: "contributionsFlag",
      hidePiggyBank: "hidePiggyBank",
    }),
    hasUserPhoto() {
      return !(this.user?.photo == "" || this.user?.photo == undefined);
    },
    contributionsEnabled() {
      return this.user?.stripeAccountId != null && this.user?.stripeAccountId != "";
    },
  },
  methods: {
    getImgUrl() {
      if (this.user?.photo == "") {
        return "#";
      }
      return process.env.URL_IMG_USER + this.user?.photo;
    },
    close() {
      this.showUserDropdown = false;
    },

    shorten: function (string, len) {
      return string?.substring(0, len + string.substring(len - 1).indexOf(" "));
    },
  },
  mounted() {
    this.show = true;
  },
};
</script>
