<template>
  <div>
    <header class="header">
      <div class="bb-container-max">
        <div class="header__container">
          <div class="header__logo">
            <router-link to="/" aria-current="page">
              <img
                src="../../assets/img/logos/logo-hellobb-dark.svg"
                alt="Logo HelloBB"
              />
            </router-link>
          </div>
          <div class="header__nav">
            <HeaderNav />
          </div>
          <div class="header__right">
            <HeaderUser />
            <div class="header__hucha" v-if="showLittlePig && !hidePiggyBank">
              <router-link to="/mi-hucha">
                <img
                  class="header-nav-icon"
                  src="../../assets/img/ico/ico-nav-hucha.svg"
                  alt=""
                />
              </router-link>
            </div>
            <!-- display only in responsive -->
            <div class="header__hucha-responsive" v-else-if="showLittlePigNoContributions && !hidePiggyBank">
              <router-link to="/mi-hucha/crear">
                <img
                  class="header-nav-icon"
                  src="../../assets/img/ico/ico-nav-hucha.svg"
                  alt=""
                />
              </router-link>
            </div>
            <div class="header__cart" v-if="featureEcommerceAvailable">
              <Cart />
            </div>
            <div class="header__burger">
              <div class="nav-m-right">
                <div @click="openMenu()" class="nav-m-right__item">
                  <img
                    class="header-nav-icon"
                    src="../../assets/img/ico/ico-burger.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="300"
      class="m-nav"
    >
      <div v-if="open" class="m-nav__overlay">
        <transition name="slide-fade">
          <div v-if="open" class="m-nav__content">
            <div class="m-nav__header">
              <div @click="openMenu()" :class="{ 'w-open': open }" class="">
                <img
                  class="ico-close"
                  src="../../assets/img/ico/ico-burger-close.svg"
                  alt=""
                />
              </div>
            </div>

            <div class="m-nav__nav">
              <LazyHydrate when-visible>
                <HeaderNav />
              </LazyHydrate>
            </div>
            <div class="m-nav__login offnav">
              <LazyHydrate when-visible>
                <HeaderUser />
              </LazyHydrate>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderNav from "./HeaderNav.vue";
import HeaderUser from "./HeaderUser.vue";
import Cart from "./Cart.vue";
import LazyHydrate from "vue-lazy-hydration";
import Features from "../../Mixins/Features";

export default {
  name: "HeaderPartial",
  components: {
    HeaderNav,
    HeaderUser,
    Cart,
    LazyHydrate,
  },
  mixins: [
    Features,
  ],
  computed: {
    ...mapGetters({
      user: "user",
      contributionsFlag: "contributionsFlag",
      isAuthenticated: "isAuthenticated",
      hidePiggyBank: "hidePiggyBank",
    }),
    showLittlePig() {
      return (
        this.contributionsFlag &&
        this.isAuthenticated &&
        this.user?.stripeAccountId != null &&
        this.user?.stripeAccountId != ""
      );
    },
    showLittlePigNoContributions() {
      return (
        this.contributionsFlag &&
        this.isAuthenticated &&
        (this.user?.stripeAccountId == null ||
        this.user?.stripeAccountId == "")
      );
    },
  },
  data() {
    return { open: false };
  },
  watch: {
    $route() {
      this.open = false;
    },
  },
  methods: {
    toggleSearch() {
      this.$store.commit("SET_SEARCHSTATE", !this.$store.state.searchState);
    },
    openMenu: function () {
      this.open = !this.open;
    },
    getImgUrl() {
      return process.env.URL_IMG_USER + this.user.photo;
    },
  },
};
</script>
