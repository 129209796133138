<template>
  <div>
    <footer class="g-footer bg-dark pad-t-4 pad-b-2">
      <div class="bb-container">
        <no-ssr>
          <div class="g-footer__cols">
            <mq-layout :mq="['m480', 'm576', 'm768']" class="g-footer__col">
              <div class="l-footer">
                <p class="l-footer__title">{{ $t("footer.downloadApp") }}</p>
                <CtaAppLinks />
              </div>
            </mq-layout>
            <div class="g-footer__col">
              <div class="l-footer">
                <div class="l-footer__title">{{ $t("footer.yourList") }}</div>
                <ul class="l-footer__list">
                  <li><router-link to="/login" rel="nofollow">{{ $t("header.logIn") }}</router-link></li>
                  <li>
                    <router-link to="/mi-lista" rel="nofollow">{{ $t("signup.title") }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="g-footer__col" v-if="featureBlogAvailable">
              <div class="l-footer">
                <p class="l-footer__title">Blog</p>
                <ul class="l-footer__list">
                  <li>
                    <a href="https://www.hellobb.net/blog/categorias/productos"
                    >Guías sobre productos</a
                    >
                  </li>
                  <li>
                    <a href="https://www.hellobb.net/blog/categorias/maternidad"
                    >Guías sobre maternidad</a
                    >
                  </li>
                  <li>
                    <a href="https://www.hellobb.net/blog/categorias/listas-de-ejemplo"
                    >Listas de ejemplo</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="g-footer__col">
              <div class="l-footer">
                <p class="l-footer__title">HelloBB</p>
                <ul class="l-footer__list">
                  <li><a href="/sobre-nosotros">{{ $t("aboutUs.aboutUs") }}</a></li>
                  <li><a rel="nofollow" href="mailto:hola@hellobb.net">{{ $t("footer.contact") }}</a></li>
                  <li>
                    <router-link to="/cookies" rel="nofollow"
                    >{{ $t("cookiesPolicy.cookiesPolicy") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/politica-privacidad" rel="nofollow"
                    >{{ $t("privacyPolicy.privacyPolicy") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/legal" rel="nofollow">{{ $t("legalNotice.legalNotice") }}</router-link>
                  </li>
                  <li>
                    <router-link to="/afiliacion-y-publicidad" rel="nofollow"
                    >{{ $t("affiliationPolicy.affiliationAndAdvertising") }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="g-footer__col">
              <div class="l-footer">
                <p class="l-footer__title">{{ $t("footer.help") }}</p>
                <ul class="l-footer__list">
                  <li>
                    <router-link to="/preguntas-frecuentes"
                    >{{ $t("faq.faq") }}</router-link
                    >
                  </li>
                  <li v-if="featureEcommerceAvailable"><router-link to="/envios" rel="nofollow">Envíos</router-link></li>
                  <li v-if="featureEcommerceAvailable">
                    <router-link to="/devoluciones" rel="nofollow"
                    >Devoluciones</router-link
                    >
                  </li>
                  <li v-if="featureEcommerceAvailable">
                    <router-link to="/programa-de-recompensas" rel="nofollow"
                    >Programa de recompensas</router-link
                    >
                  </li>
                  <li v-if="featureWebMapAvailable">
                    <router-link to="/mapa-web">Mapa web</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="g-footer__col">
              <div class="l-footer">
                <p class="l-footer__title">{{ $t("footer.followUs") }}</p>
                <div class="l-footer__social">
                  <LazyHydrate when-visible>
                    <FooterSocialIcons />
                  </LazyHydrate>
                </div>
              </div>
              <mq-layout :mq="['m992', 'm1200']" class="l-footer">
                <p class="l-footer__title">{{ $t("footer.downloadApp") }}</p>
                <CtaAppLinks />
              </mq-layout>
            </div>
          </div>
        </no-ssr>
        <div class="g-footer__copy">© helloBB 2023 — {{ $t("footer.reservedRights") }}</div>
      </div>
    </footer>
  </div>
</template>
<script>
import CtaAppLinks from "../modules/cta/CtaAppLinks.vue";
import FooterSocialIcons from "./components/FooterSocialIcons.vue";
import LazyHydrate from "vue-lazy-hydration";
import NoSSR from "vue-no-ssr";
import Features from "../Mixins/Features";

export default {
  name: "Footer",
  components: {
    CtaAppLinks,
    FooterSocialIcons,
    LazyHydrate,
    "no-ssr": NoSSR,
  },
  mixins: [
    Features
  ],
};
</script>
